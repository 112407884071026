import React from "react";
import Image from "../../elements/Image";
import { OptionsCard, OptionsRow, CardText } from "./ram.styles";

const BighornStandard = () => {
  return (
    <OptionsRow>
      <OptionsCard>
        <Image fileName={'options_ANT@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          GRUPA OCHRONY [ANT]

          <ul>
            <li>4 regulowane haki do mocowania ładunku</li>
            <li>Oświetlenie skrzynki ładunkowej</li>
            <li>Specjalistyczne zabezpieczenie skrzyni ładunkowej</li>
            <li>Przegroda ładunkowa (standard z Rambox)</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_A63@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          WYPOSAŻENIE 2 POZIOMU [A63]

          <ul>
            <li>System zdalnego uruchamiania</li>
            <li>Automatyczna podwójna strefa klimatyzacji</li>
            <li>Podgrzewane przednie siedzenia</li>
            <li>Podgrzewana kierownica</li>
            <li>Możliwość regulacji pedałów</li>
            <li>Alarm bezpieczeństwa</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_UJV@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText color={'#a7a7a7'} className={`text`}>
          WYŚWIETLACZ 8,4 CALA [UJV]

          <ul>
            <li>Apple CarPlay®</li>
            <li>Android Auto ™</li>
            <li>Europejski system nawigacji</li>
          </ul>
        </CardText>
      </OptionsCard>

    </OptionsRow>
  )
}

export default BighornStandard