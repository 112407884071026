import React from "react";
import Image from "../../elements/Image";
import { OptionsCard, OptionsRow, CardText } from "./ram.styles";

const BighornOptions = () => {
  return (
    <OptionsRow>
      <OptionsCard>
        <Image fileName={'options_UJX@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          12-CALOWY EKRAN DOTYKOWY [UJX]
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_MRA@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          CHROMOWANE BOCZNE STOPNIE [MRA]
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_MRU@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          CZARNE BOCZNE STOPNIE [MRU]
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_XB9@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          RAMBOX [XB9]
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_GWJ@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          PODWÓJNY PANORAMICZNY DACH  [GWJ]

          <ul>
            <li>Dwupanelowy panoramiczny dach obejmuje przesuwny przedni oraz tylny szklany panel z komfortowym sterowaniem elektrycznym i elektryczną osłoną przeciwsłoneczną</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_SER@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          ZAWIESZENIE PNEUMATYCZNE [SER]

          <ul>
            <li>Zawieszenie pneumatyczne obejmuje pięć ustawień wysokości. Sterowanie w górę i w dół odbywa się za pomocą przycisku w środkowej konsoli pojazdu.</li>
            <li>Zapewnia łatwość wsiadania i wysiadania, wyższą wysokość jazdy dla dodatkowego prześwitu, a także niższą wysokość jazdy dla aerodynamiki i lepszej wydajności.</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_ADB@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          GRUPA OCHRONY [ADB]

          <ul>
            <li>Osłona podwozia przedniego zawieszenia</li>
            <li>Osłona zbiornika paliwa</li>
            <li>Osłona podwozia przekładni kierowniczej</li>
            <li>Osłona skrzynki rozdzielczej</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_MWK@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          WIELOFUNKCYJNA KLAPA TYLNA [MWK]

          <ul>
            <li>Nowa aranżacja klapy tylnej oferuje pierwszy w swoim rodzaju podział 60/40. To sprawia, że dostęp do bagażu jest o wiele prostszy, a sama część ładunkowa jest niesamowicie wytrzymała.</li>
          </ul>
        </CardText>
      </OptionsCard>

      <OptionsCard>
        <Image fileName={'options_CS7@2x.jpg'} alt={'RAM Bighorn - opcje dodatkowe'}/>
        <CardText className={`text`}>
          POKRYWA TRIFOLD [CS7]

          <ul>
            <li>Fabryczne pokrowce na skrzynie ładunkowe</li>
          </ul>
        </CardText>
      </OptionsCard>
    </OptionsRow>
  )
}

export default BighornOptions